import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
// import FormHome from "../components/Home/FormHome";
// import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
// import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_6";
import BlockSecondary from "../components/block/Block_4";
// import BlockAbout from "../components/block/Block_1";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { ButtonContent } from "../components/global/boton/ButtonContent";
import Carousel from "../components/Home/Carousel";



import marca1 from "../assets/image/marca/marca-1.png";
import marca2 from "../assets/image/marca/marca-2.png";
import marca3 from "../assets/image/marca/marca-3.png";
import marca4 from "../assets/image/marca/marca-4.png";
import marca5 from "../assets/image/marca/marca-5.png";
import marca6 from "../assets/image/marca/marca-6.png";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  console.log(rpdata)
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <HeroSection
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/videos2-986b9.appspot.com/o/tijuana-mexico-roof-install-2023-11-27-05-00-22-utc%20(online-video-cutter.com).mp4?alt=media&token=8c9408ff-5cce-4e77-9a09-3122e2ee2c95"}
        />


        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.[0]}
          image2={rpdata?.gallery?.[1]}
          image3={rpdata?.gallery?.[2]}
          image4={rpdata?.gallery?.[3]}
          sloganPrincipal={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              // text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
              vimeoVideo
            />
            : null
        }

        <Directories />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[4]}
          image2={rpdata?.gallery?.[5]}
          image3={rpdata?.gallery?.[6]}
        />

        {/* <CounterUp image={rpdata?.stock?.[25]} /> */}

        <div className='bgCountent relative my-28' style={{ backgroundImage: `url("${rpdata?.stock?.[25]}")` }}>
          <div className="w-full grid grid-cols-3 absolute left-0 right-0 -top-[90px]">
            <div className="flex justify-center">
              <img src={marca1} alt="marca1" className="w-full md:w-1/3" />
            </div>
            <div className="flex justify-center">

              <img src={marca2} alt="marca2" className="w-full md:w-1/3" />
            </div>
            <div className="flex justify-center">
              <img src={marca3} alt="marca3" className="w-full md:w-1/3" />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className='text-center py-4 relative text-white text-4xl px-5 md:px-[20%]'>Our different GAF asphalt shingles are under a manufacturers lifetime warranty.</p>
            <ButtonContent />
          </div>

          <div className="w-full grid grid-cols-3 absolute left-0 right-0 -bottom-[90px]">
            <div className="flex justify-center">
              <img src={marca4} alt="marca4" className="w-full md:w-1/3" />
            </div>
            <div className="flex justify-center">

              <img src={marca5} alt="marca5" className="w-full md:w-1/3" />
            </div>
            <div className="flex justify-center">
              <img src={marca6} alt="marca6" className="w-full md:w-1/3" />
            </div>
          </div>
        </div>

        {/* <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[3]}
          listsAbout={true}
        /> */}


        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />

        }

        <div className="container mx-auto">
          <h1 className="text-center text-[50px] md:text-[90px] font-bold my-8 text-white font-1">Featured Projects</h1>
          <Carousel />
        </div>

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.gallery} />
            </div>
            :
            null

        }
        <div className="mt-20 mb-10 placeholder-yellow-100">
          <h2 className="text-center font-1 text-white pb-10 md:pb-5">
            {rpdata?.labels?.general?.titleMap}
          </h2>
          <div className="w-11/12 md:w-4/5 mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {
              rpdata?.dbPrincipal?.location?.map((item, index) => {
                return <p key={index} className="text-center text-white">{item.address}</p>;
              })
            }
          </div>
        </div>
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[8]}")` }}
        >
          {/* <div className="relative">
            <FormHome />
          </div> */}
          <h2 className='text-center py-4 relative text-white font-1 text-[50px] md:text-[90px]'>Request a Quote</h2>
          <div className="pt-10 flex justify-center">
            <Link
              to="/contact"
              className="font-bold text-2xl block  group text-white"
            >
              <span className="uppercase border-b-[2px] pb-4 relative">

                get a quote
                <span className="absolute -bottom-[13px] -right-4 transform group-hover:translate-x-2 transition-transform duration-300">
                  <BsArrowRight className="group-hover:scale-125 transition-transform duration-300 " />
                </span>
              </span>
            </Link>
          </div>
        </div>

        {/* <Map /> */}

      </div>
    </BaseLayout>
  );
}

export default Home;
