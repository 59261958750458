import React, { useContext, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { GlobalDataContext } from '../../context/context';

const Carousel = () => {
    const { rpdata } = useContext(GlobalDataContext);
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? rpdata.gallery.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === rpdata.gallery.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <div className="relative w-full h-[500px] overflow-hidden">
            <div className="flex justify-center items-center w-full h-full">
                {rpdata.gallery.map((image, index) => {
                    let positionClass = '';

                    if (index === currentIndex) {
                        positionClass = 'centerImage';
                    } else if (index === (currentIndex === 0 ? rpdata.gallery.length - 1 : currentIndex - 1)) {
                        positionClass = 'leftImage';
                    } else if (index === (currentIndex === rpdata.gallery.length - 1 ? 0 : currentIndex + 1)) {
                        positionClass = 'rightImage';
                    } else {
                        positionClass = 'hiddenImage';
                    }

                    return (
                        <div key={index} className={`absolute ${positionClass} transition-all duration-500 ease-in-out`}>
                            <img src={image} alt={`Slide ${index}`} className="w-full h-full object-cover" />
                        </div>
                    );
                })}
            </div>
            <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-between px-4">
                <button onClick={prevSlide} className="bg-white rounded-full p-2 shadow-lg">
                    <FaChevronLeft size={24} />
                </button>
                <button onClick={nextSlide} className="bg-white rounded-full p-2 shadow-lg">
                    <FaChevronRight size={24} />
                </button>
            </div>
        </div>
    );
};

export default Carousel;
