import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// import { ButtonContent } from "../global/boton/ButtonContent";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[0]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[1]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[2]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[3]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto lg:h-[100vh] h-[1280px] text-white">
          <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center text-start">
            <h2 className="text-[2.5em] font-1 lg:text-[5em] text-white">
              {rpdata?.dbSlogan?.[8].slogan}
            </h2>
            {/* <p className="text-white text-center">{rpdata?.dbSlogan?.[0].slogan}</p> */}
            <div className="pt-10">
                            <Link
                                to="/contact"
                                className="font-bold text-2xl block  group"
                            >
                                <span className="uppercase border-b-[2px] pb-4 relative">

                                    get a quote
                                <span className="absolute -bottom-[13px] -right-4 transform group-hover:translate-x-2 transition-transform duration-300">
                                    <BsArrowRight className="group-hover:scale-125 transition-transform duration-300" />
                                </span>
                                </span>
                            </Link>
                        </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
