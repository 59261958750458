import { BiMenu, BiPhoneCall } from "react-icons/bi";
import { GlobalDataContext } from "../../../context/context";
import { Link, NavLink } from "react-router-dom";
import { RiFileEditLine } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";
import { GoChevronDown, GoChevronUp } from "react-icons/go";

const { useEffect, useContext } = require("react");
const { useRef } = require("react");
const { useState } = require("react");


const socialMediaNames = {
    tiktok1: 'TikTok',
    pinterest: 'Pinterest',
    vimeo: 'Vimeo',
    // Agrega más si es necesario
};


const HeaderSticky = () => {

    const { rpdata } = useContext(GlobalDataContext);
    const headerRef = useRef(null);

    const [isSticky, setSticky] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(false)
    const [openSubMenuGallery, setOpenSubMenuGallery] = useState(false)

    const handleClickSubMenu = () => {
        return setOpenSubMenu(!openSubMenu)
    }
    const handleClickSubMenuGallery = () => {
        return setOpenSubMenuGallery(!openSubMenuGallery)
    }
    const handleScroll = () => {
        setSticky(window.scrollY > headerRef.current.getBoundingClientRect().bottom);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerReferencia = !openMenu ? headerRef : null;



    //sub menu
    const subitems = rpdata?.dbServices?.slice(0, 9).map((itemsMenu) => {
        return (
            {
                subname: itemsMenu.name,
                sublink: `/${itemsMenu.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`,
            }
        )
    })

    //sub menu
    const subitemsGallery = rpdata?.landings?.map((itemsGallery, index) => {
        return (
            {
                subname: itemsGallery.name,
                sublink: `/gallery/${itemsGallery.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`,
            }
        )
    })
    // menu

    const dbMenu = [
        {
            name: `Home`,
            link: `/`,
            child: false,

        },
        {
            name: `About`,
            link: `/about`,
            child: false,
        },
        {
            name: `Services`,
            link: `/services`,
            child: rpdata?.autoGntLandingFromService,
            submenu: [...(subitems ? subitems : [])],
            click: handleClickSubMenu,
            opens: openSubMenu,
        },
        {
            name: `Gallery`,
            link: `/gallery`,
            child: rpdata?.customLinks,
            submenu: [...(subitemsGallery ? subitemsGallery : [])],
            click: handleClickSubMenuGallery,
            opens: openSubMenuGallery,
        },
        {
            name: `Contact`,
            link: `/contact`,
            child: false,
        },
    ]


    // agregar la pestaña de Video al array de dbMenu

    const yt = {
        name: `Our Videos`,
        link: `/our-videos`,
        child: false,
    }

    if (rpdata?.ytGallery?.linkCanalYT) {
        const num = dbMenu.length - 1
        dbMenu.splice(num, 0, yt)
    }
    //  fin de agregar pestaña de Video

    // agregar la pestaña de reviews al array de dbMenu

    const el = {
        name: `Reviews`,
        link: `/reviews`,
        child: false,
    }

    if (rpdata?.reviews?.activo && rpdata?.reviews?.isHomeOnly === false) {
        const num = dbMenu.length - 1
        dbMenu.splice(num, 0, el)
    }
    //  fin de agregar pestaña de reviews

    return (
        <header
            ref={headerReferencia}
            className={`
            w-full fixed top-0 z-50 border-y transition-all duration-300 ease-in-out
            ${isSticky ? 'bg-white shadow-md' : !openMenu ? 'bg-[#7892a4b4]' : 'bg-[#7892a4]'}
        `}
        >
            <div className={`flex justify-between w-full ${openMenu && 'border'}`}>
                <div className={`w-[90%] md:w-[20%] ${isSticky ? 'max-h-[100px]' : 'max-h-[180px] md:max-h-[290px]'}  flex self-center `}>
                    <div className={`${isSticky ? 'max-h-[100px]' : 'max-h-[180px] md:max-h-[290px] -mt-14'}`}>
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt="logo"
                            loading="lazy"
                            className={`w-full object-contain mx-auto ${isSticky ? 'h-[100px]' : 'h-[180px] md:h-[290px]'}`}
                        />
                        <p className={`text-center  font-bold   ${isSticky ? 'text-xs -mt-5' : 'text-white text-lg md:text-2xl -mt-8 md:-mt-10 mb-5'}`}>Lic.# CCC1335747</p>

                    </div>
                </div>
                <div className={`flex justify-end md:w-[80%] lg:w-[75%] w-full ${isSticky ? 'h-[100px]' : 'h-[180px] md:h-[285px]'} self-center`}>
                    <div className="hidden md:flex items-center border-x h-full px-5">
                        <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} className={`flex items-center ${isSticky ? 'text-[#164EA3]' : 'text-white'} `}>
                            <div className="w-[15%] self-center mr-3">
                                <BiPhoneCall fontSize={30} />
                            </div>
                            <div>
                                <h5 className="text-[18px]">
                                    {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                </h5>
                            </div>
                        </a>
                    </div>
                    <div className="hidden md:flex items-center border-x h-full px-5">
                        <Link to={'/contact'} className={`flex items-center ${isSticky ? 'text-[#164EA3]' : 'text-white'} `}>
                            <div className="w-[15%] self-center mr-3">
                                <RiFileEditLine fontSize={30} />
                            </div>
                            <div>
                                <h5 className="text-[18px] upe">
                                    Get A Quote
                                </h5>
                            </div>
                        </Link>
                    </div>
                    <div className="flex items-center h-full px-5">
                        <button
                            className={`flex items-center text-5xl ${isSticky ? 'text-[#164EA3]' : 'text-white'} `}
                            onClick={() => setOpenMenu(!openMenu)}
                        >
                            {
                                openMenu ? <MdOutlineClose /> : <BiMenu />

                            }
                        </button>
                    </div>
                </div>
            </div>
            <div className={`${openMenu ? 'h-screen  bg-[#7892a4]' : 'hidden'}`}>
                <div className="w-full max-h-[75vh] overflow-y-auto">
                    <div className="w-full md:w-4/5 mx-auto flex flex-col md:flex-row items-center md:justify-between py-10 gap-5 ">
                        <div className="w-full text-center md:text-start md:w-[40%] space-y-6">
                            {
                                dbMenu.map((items, index) => {
                                    return (
                                        items.child ? (
                                            <div key={index} className=' text-white' onClick={items.click}>
                                                <div className='flex justify-center md:justify-start items-center text-2xl font-bold uppercase'>
                                                    {items.name}
                                                    {
                                                        items.opens ?
                                                            <GoChevronUp className='ml-1' />
                                                            : <GoChevronDown className='ml-1' />
                                                    }
                                                </div>
                                                <ul className={items.opens ? 'block mt-3 mx-5  space-y-2' : 'hidden'}>
                                                    {
                                                        items.submenu.map((itemSub, index) => {
                                                            return (
                                                                <NavLink key={index} to={itemSub.sublink}>
                                                                    <li className='p-2 text-lg font-semibold  uppercase'>
                                                                        {itemSub.subname}
                                                                    </li>
                                                                </NavLink>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        ) : (
                                            <ul key={index} className='space-y-2'>
                                                <NavLink to={items.link}>
                                                    <li className='text-2xl font-bold  text-white uppercase'>
                                                        {items.name}
                                                    </li>
                                                </NavLink>
                                            </ul>
                                        )
                                    )
                                })
                            }
                        </div>
                        <div className="w-[30%]"></div>
                        <div className="w-[30%] flex flex-col ">
                            <h3 className="text-white text-2xl uppercase font-bold pb-5">Socialize</h3>
                            <ul className="flex flex-col justify-center gap-5">
                                {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                    const displayName = socialMediaNames[item.name.toLowerCase()] || item.name;
                                    return (
                                        <li key={index} className="text-white">
                                            <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i
                                                    className={`fab fa-${item.icon}`}
                                                    aria-hidden="true"
                                                />
                                                <span className='uppercase pl-2'>{displayName}</span>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderSticky;
